import React from "react"
import styled from "styled-components"
import { ResourceBlock } from "./ResourceBlock"

export const ResourceBlockContainer = ({ type, text, children }) => {
  const fil = SVGs.filter(a => a.type === type)
  console.log(fil[0].img)

  return (
    <Wrapper>
      <Heading>
        <div>{fil[0].img}</div>
        <p>{type}</p>
      </Heading>
      <Container>
        {text.map(a => (
          <ResourceBlock text={a} />
        ))}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;
`

const Container = styled.div`
  display: flex;
  justify-items: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    justify-items: center;
  }

  p {
    margin: 0;
    padding: 1rem;
    color: ${props => props.theme.text.main};
  }
`

const Heading = styled.div`
  display: flex;
  justify-items: space-between;
  align-items: center;

  p {
    margin-left: 1rem;
    text-transform: uppercase;
    color: ${props => props.theme.text.main};
  }

  path {
    stroke: ${props => props.theme.text.main};
  }
`

const SVGs = [
  {
    type: "blogs",
    img: (
      <svg
        width="27"
        height="34"
        viewBox="0 0 27 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 2.02624C1.09195 6.80783 2.09472 11.4654 2.41997 16.219C2.68017 20.0219 2.62085 23.8322 2.62085 27.6412C2.62085 28.5032 2.53851 29.2626 2.48924 30.1001C2.44191 30.9047 2.37149 31.6881 2.37149 32.4968C2.37149 33.1598 2.23287 32.9411 2.44075 32.8223C2.81016 32.6113 3.32433 32.6947 3.72912 32.67C6.16605 32.5211 8.60738 32.6566 11.0437 32.5176C13.8172 32.3593 16.4004 32.0665 19.1826 32.1989C20.3979 32.2568 21.4362 32.6303 22.5974 32.947C23.4891 33.1902 24.3055 33.2184 25.2019 33.3488C25.8323 33.4405 25.8096 33.1973 25.9361 32.4899C26.3929 29.9348 26.0498 27.4148 25.7422 24.8705C25.4954 22.8297 25.0953 20.8188 24.8763 18.775C24.7329 17.4364 24.7401 16.1263 24.6755 14.7852C24.5609 12.408 24.2219 10.0333 24.1906 7.65072C24.173 6.31061 24.0659 4.97561 24.0659 3.64016C24.0659 3.30739 24.1925 2.66775 24.0521 2.35179C23.9563 2.13627 22.5313 2.16348 22.3481 2.15785C20.5434 2.10232 18.7371 2.03238 16.9314 2.02624C15.0543 2.01985 13.2039 1.80302 11.3346 1.77688C9.6641 1.75351 8.05412 1.63199 6.3959 1.53444C4.65027 1.43176 2.87455 1.52752 1.12468 1.52752"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 10.2551C10.9067 10.2551 14.8133 10.2551 18.72 10.2551"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 14.6189C11.6633 14.6189 14.3196 14.7526 16.9796 14.8683"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 19.7308C10.6106 19.7308 16.2213 19.7308 21.8319 19.7308"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 25.7156C11.1976 25.7156 15.3952 25.7156 19.5927 25.7156"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    type: "video",
    img: (
      <svg
        width="36"
        height="29"
        viewBox="0 0 36 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.77242 4.40831C2.39698 10.5151 3.13196 16.574 3.13196 22.7168C3.13196 24.3139 3.0681 25.3791 2.61836 26.8407C1.90384 29.1629 5.32047 28.3396 6.63656 28.2758C11.6613 28.0322 16.6826 28.0643 21.7124 28.0643C24.0368 28.0643 26.3474 28.1755 28.646 27.7924C29.7769 27.604 30.6132 27.0183 31.8334 26.9767C32.7786 26.9445 33.8751 26.9523 34.7942 26.6897C35.1457 26.5893 34.9558 24.0007 34.8848 23.6534C34.378 21.1758 33.7172 18.7174 33.1778 16.2363C32.7342 14.1954 31.9542 12.4466 31.9542 10.2997C31.9542 7.66114 31.9542 5.02262 31.9542 2.38411C31.9542 2.106 32.0663 1.49178 31.8938 1.26626C31.4979 0.748461 28.7901 1.14541 28.1475 1.14541C24.5422 1.14541 20.9369 1.14541 17.3316 1.14541C15.0003 1.14541 12.6689 1.14541 10.3375 1.14541C8.67068 1.14541 6.94295 2.08104 5.30723 2.44453C4.14943 2.70182 3.04144 2.74446 1.92348 3.06388C0.666961 3.42288 0.975048 3.66599 1.22861 4.68022"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.5726 9.47251C10.9563 11.8705 10.5726 14.357 10.5726 16.775C10.5726 17.4876 10.4826 18.3394 10.7587 19.017C10.866 19.2803 11.1137 20.6914 11.4285 20.6914C11.641 20.6914 11.8556 20.5675 12.0331 20.4682C13.1167 19.8621 14.2111 19.2773 15.2983 18.6774C16.9698 17.7552 18.8901 16.9318 20.331 15.6494C21.2037 14.8728 22.0941 14.1697 23.0288 13.4726C23.2434 13.3125 23.3393 13.1224 23.5776 13.0308C23.7123 12.979 23.4767 12.9832 23.4567 12.9796C23.0815 12.9114 22.6874 12.7466 22.3311 12.6168C19.8586 11.7161 17.3142 10.7102 14.7309 10.2074C14.2541 10.1146 13.8253 9.86057 13.3401 9.81206C13.0507 9.78311 12.753 9.70835 12.4796 9.60275C12.1225 9.46474 11.7985 9.33859 11.4145 9.26785C11.1834 9.22529 10.9414 9.05389 10.7401 9.05389"
          stroke="#F0F4F8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    type: "podcast",
    img: (
      <svg
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.367 16.8743C12.367 15.5043 12.367 14.1342 12.367 12.7642C12.367 12.2216 12.367 11.6791 12.367 11.1365C12.367 10.8389 12.2568 10.2986 12.465 10.0695C12.8071 9.69318 12.7236 8.78068 12.955 8.29479C13.251 7.67308 13.1627 6.98964 13.5375 6.42755C14.4678 5.03202 16.5141 5.40955 17.996 5.40955C18.6447 5.40955 18.9879 6.13029 19.1283 6.72696C19.2017 7.03914 19.3728 7.28829 19.444 7.60887C19.538 8.03186 19.5058 8.46124 19.6182 8.88273C19.8256 9.66037 19.7162 10.5806 19.7162 11.3869C19.7162 12.3667 19.9122 13.2678 19.9122 14.2286C19.9122 14.8746 19.9122 15.5206 19.9122 16.1666C19.9122 16.742 20.0743 17.1683 19.4005 17.1683C17.0233 17.1683 14.6462 17.1683 12.269 17.1683"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.83936 9.42709C9.37451 10.1629 9.3293 10.9069 9.3293 11.7897C9.3293 12.913 9.3293 14.0362 9.3293 15.1595C9.3293 15.6345 9.6747 15.8955 9.72126 16.3843C9.78259 17.0283 10.1485 17.64 10.2874 18.2788C10.5843 19.6444 11.396 20.6896 12.7535 21.1259C13.6162 21.4032 14.747 21.1952 15.6551 21.3328C16.712 21.4929 17.9602 21.4736 19.0302 21.3763C20.5642 21.2369 21.7194 20.2944 22.6558 19.1498C23.0351 18.6862 23.4036 18.1932 23.5813 17.6092C23.6626 17.342 23.8145 17.2437 23.8372 16.9723C23.8553 16.7542 23.8886 16.4301 23.9787 16.2319C24.2465 15.6426 23.928 14.8031 24.1692 14.185C24.3958 13.6044 24.4016 12.7477 24.4197 12.1164C24.4434 11.2852 24.5176 10.4678 24.5176 9.62307"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.1885 21.8718C16.1885 23.2281 15.5326 25.2634 16.1885 26.5753"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.7993 26.7712C13.9858 26.7712 17.1722 26.7712 20.3587 26.7712C21.0448 26.7712 21.8417 26.8844 22.46 26.5752"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6206 8.15324C15.3637 8.44323 16.1825 8.34922 16.9723 8.34922"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.8169 10.995C15.6033 11.0064 16.3813 11.093 17.1686 11.093"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.3267 13.7387C15.9198 13.9021 17.5284 13.8367 19.1281 13.8367"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.9927 1.19598C14.7506 1.02756 13.4827 1.12121 12.2364 1.21775C10.7003 1.33675 9.01297 1.67428 7.59824 2.27386C5.1486 3.31204 2.96779 4.35921 1.92574 6.98824C1.00819 9.30316 1.00029 11.8548 1.00029 14.2993C1.00029 15.9206 0.977425 17.4335 1.33781 19.0191C1.60472 20.1936 1.98813 21.129 2.57356 22.1766C3.92694 24.5984 5.74924 26.8072 7.91398 28.535C8.90382 29.3251 10.2162 29.9418 11.3654 30.473C12.5816 31.0352 13.897 31.2556 15.1652 31.6707C16.3774 32.0674 17.6268 31.9646 18.8888 31.9646C20.7271 31.9646 22.4869 31.846 24.3163 31.6815C25.7101 31.5563 26.8362 31.1116 28.1161 30.5492C29.4483 29.9639 30.4474 28.613 31.2354 27.4353C32.1267 26.1033 32.5286 24.414 32.825 22.8625C33.0621 21.6221 33.2388 20.4793 33.2388 19.2151C33.2388 18.2225 33.2388 17.2299 33.2388 16.2373C33.2388 15.3056 33.0714 14.5005 32.9502 13.5916C32.6705 11.4937 31.6821 9.48629 30.4733 7.79392C29.6809 6.68453 28.8002 5.61817 27.5227 5.03934C26.756 4.69193 25.949 4.53618 25.1492 4.2772C24.3088 4.00506 23.4776 3.75003 22.6178 3.54772C21.0391 3.17626 19.5775 2.66067 18.1049 2.09966C17.8593 2.00609 17.6523 2.15347 17.4407 1.95812C17.2281 1.76186 16.9558 1.72186 16.684 1.63693C16.4227 1.55526 15.7144 1.22743 15.6007 1"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
]
